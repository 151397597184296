export default function Sakura() {
  return (
    <div className="background light">
      <div className="sakuras">
        <div className="sakura"></div>
        <div className="sakura"></div>
        <div className="sakura"></div>
        <div className="sakura"></div>
        <div className="sakura"></div>
        <div className="sakura"></div>
        <div className="sakura"></div>
        <div className="sakura"></div>
        <div className="sakura"></div>
      </div>
    </div>
  );
}
